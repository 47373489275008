<template>
  <div id="modal-view-report">
    <b-modal
      :id="id"
      size="lg"
      hide-footer
      centered
      @show="fetchData(urlQuery)"
    >
      <div>
        <!--phần header-->
        <button-all-header
          :contentBtnAdd="''"
          :arrayExcel="[]"
          :hideExportFile="false"
          :showBtnMultiDelete="showBtnMultiDelete"
          :hideImportFile="false"
          :hideDowload="false"
          :hideAdd="false"
          :hideDelete="false"
          @clickExportExcel="() => {}"
          @search="search($event)"
        />
        <!--Phần Bảng -->
        <vue-good-table
          ref="user-table"
          style-class="vgt-table"
          :columns="columns"
          :rows="dataList || []"
          :sort-options="{
            enabled: false,
          }"
          :select-options="{
            enabled: false,
            vertialAlignTop: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Họ tên -->
            <span v-if="props.column.field == 'name'">
              {{ props.row.name }}
            </span>
            <!-- ngày sinh -->
            <span v-if="props.column.field == 'year'">
              {{ props.row.year }}
            </span>
            <!-- Chức năng -->
            <span v-if="props.column.field == 'action'">
              <span>
                <feather-icon
                  v-b-tooltip.hover.top="'Xem tài liệu'"
                  icon="EyeIcon"
                  size="18"
                  class="text-body"
                  @click="showPDF(props.row.id, props.row.name)"
                />
                <span>
                  <b-dropdown
                    variant="link"
                    boundary="viewport"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        v-b-tooltip.hover.top="'Tải xuống'"
                        icon="DownloadIcon"
                        size="18"
                        class="text-body"
                      />
                    </template>
                    <!-- xem chi tiet  -->
                    <b-dropdown-item
                      @click="downloadFile(props.row.id, 'Word', props.row.name)"
                    >
                      <feather-icon
                        icon="DownloadIcon"
                        size="18"
                        class="mr-50"
                      />
                      <span>Xuất file Word</span>
                    </b-dropdown-item>
                    <!-- xem bao cao  -->
                    <b-dropdown-item @click="downloadFile(props.row.id, 'PDF', props.row.name)">
                      <feather-icon
                        icon="DownloadIcon"
                        size="18"
                        class="mr-50"
                      />
                      <span>Xuất file PDF</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>
            </span>
          </template>
        </vue-good-table>
        <my-pagination
          :totalItems="totalRecord"
          :currentPage="urlQuery.pageNumber"
          @pageClick="pageChange"
        />
        <!-- modal xem file pdf -->
        <modal-see-pdf
          :id="modalSeePdf"
          :pdfsrc="pdfSrc"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BBadge,
  VBTooltip,
  BModal,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ModalSeePdf from './ModalSeePDF.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    ModalSeePdf,
    BModal,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    id: {
      type: String,
      default: 'confirm-modal',
    },
    modeTypeView: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      pdfSrc: '',
      totalRecord: 10,
      modalSeePdf: 'modal-see-pdf',
      columns: [
        {
          label: 'TÊN ĐƠN VỊ',
          field: 'name',
          sortable: false,
        },
        {
          label: 'NĂM BÁO CÁO',
          field: 'year',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'action',
          sortable: false,
          width: '175px',
        },
      ],
      dataList: [],
      currentPage: 0,
      urlQuery: {
        rType: this.modeTypeView,
        year: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      idDetail: '',
      modalEditReport: 'modal-edit-final-report',
      confirmModalId: 'confirm-modal',
      modalContent: '',
      modalCreateReport: 'comfirm-create-final-report',
      deleteIds: [],
      showBtnMultiDelete: false,
      fileType: '',
      dataDetail: {},
      abc: '',
    }
  },
  methods: {

    // Lấy danh sách
    async fetchData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_FINAL_REPORT, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },

    // Lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchData(this.urlQuery)
      }
    },

    showPDF(id, name) {
      const paload = {
        reportMonthAccidentId: id,
        reportFormat: 'PDF',
      }
      axiosApiInstance({
        url: '/ReportMonthAccident/file-report-month-accident', // your url
        method: 'GET',
        responseType: 'blob', // important
        params: paload,
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        this.pdfSrc = url
      })
      this.$bvModal.show(this.modalSeePdf)
    },

    // Tải file có trong giấy phép lao dộng
    downloadFile(id, val, name) {
      const paload = {
        reportMonthAccidentId: id,
        reportFormat: val,
      }
      axiosApiInstance({
        url: '/ReportMonthAccident/file-report-month-accident', // your url
        method: 'GET',
        responseType: 'blob', // important
        params: paload,
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        const fileName = val === 'Word' ? `${name}.docx` : `${name}.pdf`
        link.setAttribute('download', fileName) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },
  },
}
</script>

<style lang="scss">
#modal-view-report {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }
  .win{
    .btn {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .border-status {
    border-radius: 4px;
  }
}
</style>
