var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"modal-view-report"}},[_c('b-modal',{attrs:{"id":_vm.id,"size":"lg","hide-footer":"","centered":""},on:{"show":function($event){return _vm.fetchData(_vm.urlQuery)}}},[_c('div',[_c('button-all-header',{attrs:{"contentBtnAdd":'',"arrayExcel":[],"hideExportFile":false,"showBtnMultiDelete":_vm.showBtnMultiDelete,"hideImportFile":false,"hideDowload":false,"hideAdd":false,"hideDelete":false},on:{"clickExportExcel":function () {},"search":function($event){return _vm.search($event)}}}),_c('vue-good-table',{ref:"user-table",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataList || [],"sort-options":{
          enabled: false,
        },"select-options":{
          enabled: false,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'name')?_c('span',[_vm._v(" "+_vm._s(props.row.name)+" ")]):_vm._e(),(props.column.field == 'year')?_c('span',[_vm._v(" "+_vm._s(props.row.year)+" ")]):_vm._e(),(props.column.field == 'action')?_c('span',[_c('span',[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Xem tài liệu'),expression:"'Xem tài liệu'",modifiers:{"hover":true,"top":true}}],staticClass:"text-body",attrs:{"icon":"EyeIcon","size":"18"},on:{"click":function($event){return _vm.showPDF(props.row.id, props.row.name)}}}),_c('span',[_c('b-dropdown',{attrs:{"variant":"link","boundary":"viewport","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Tải xuống'),expression:"'Tải xuống'",modifiers:{"hover":true,"top":true}}],staticClass:"text-body",attrs:{"icon":"DownloadIcon","size":"18"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.downloadFile(props.row.id, 'Word', props.row.name)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"DownloadIcon","size":"18"}}),_c('span',[_vm._v("Xuất file Word")])],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.downloadFile(props.row.id, 'PDF', props.row.name)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"DownloadIcon","size":"18"}}),_c('span',[_vm._v("Xuất file PDF")])],1)],1)],1)],1)]):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord,"currentPage":_vm.urlQuery.pageNumber},on:{"pageClick":_vm.pageChange}}),_c('modal-see-pdf',{attrs:{"id":_vm.modalSeePdf,"pdfsrc":_vm.pdfSrc}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }